<template>
    <div class="app-container" :class="{'mobile-menu-expanded': $store.state.menuExpanded}">
          <div class="main-wrapper">
            <main :class="{'mobile-menu-expanded': $store.state.menuExpanded}">
                <slot/>
            </main>
          </div>
    </div>
</template>

<script>
    import Footer from "../components/Footer";

    export default {
        name: "AppLayout",
        components: { Footer }
    }
</script>

<style lang="scss" scoped>
    .app-container {
        @apply grid h-screen;

        grid-template-columns: auto 1fr;
        grid-template-rows: auto 1fr;
        grid-template-areas: "menubar menubar" "sidebar content";

        .main-wrapper {
            @apply overflow-auto flex flex-col min-h-screen;
            grid-area: content;

            main {
                @apply flex-1 min-h-screen;
            }
        }
    }
</style>