import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import {i18n} from './locale/i18n';
import './css/app.scss';
import VueHead from 'vue-head';
import Notifications from 'vue-notification';
import Vuelidate from 'vuelidate';
import Multiselect from 'vue-multiselect';
import VueGoodTablePlugin from 'vue-good-table';
import VModal from 'vue-js-modal';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import PrettyCheck from 'pretty-checkbox-vue/check';
import VueTippy, {TippyComponent} from "vue-tippy";
import Clipboard from 'v-clipboard';

Vue.prototype.$moment = require('moment-timezone');
Vue.prototype.$axios = require('axios');

Vue.prototype.$axios.defaults.headers['Content-Type'] = 'application/json';
Vue.prototype.$axios.defaults.baseURL = process.env.VUE_APP_API_URL;
Vue.prototype.$axios.interceptors.request.use((config) => {
    if (store.getters.token)
        config.headers.common['Authorization'] = 'Bearer ' + store.getters.token.bearer;

    return config;
});

Vue.prototype.$axios.interceptors.response.use((res) => res, (error) => {
    if (error && error.response && error.response.status && error.response.status === 401) {
        store.dispatch('logout');
        router.push({name: 'login'});
    }

    return Promise.reject(error);
});

Vue.prototype.$larerror = (error, fallback = 'error') => {
    if (!error)
        return fallback;

    if (error.errors && error.errors.constructor === Object)
        return error.errors[Object.keys(error.errors)[0]][0];
    else if (error.errors && error.errors.constructor === Array)
        return error.errors[0];

    if (error.message && error.message.constructor === String && error.message.length)
        return error.message;

    return error || fallback;
};

Vue.config.productionTip = false;

Vue.use(VueHead);
Vue.use(Notifications);
Vue.use(Vuelidate);
Vue.component('multiselect', Multiselect);

import 'vue-good-table/dist/vue-good-table.css';

Vue.use(VueGoodTablePlugin);
Vue.use(VModal, {dynamic: true, injectModalsContainer: true});

Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

Vue.component('p-check', PrettyCheck);

Vue.use(VueTippy);
Vue.component("tippy", TippyComponent);

Vue.use(Clipboard);

import "vue-multiselect/dist/vue-multiselect.min.css";

import {library} from '@fortawesome/fontawesome-svg-core';
import {} from '@fortawesome/free-solid-svg-icons';
import {
    faEye as farEye,
    faEyeSlash as farEyeSlash,
} from '@fortawesome/free-regular-svg-icons';
import {
    faAngleDown as farAngleDown, faSearch as farSearch, faTimes as farTimes, faCheck as farCheck, faSave as farSave,
    faFilter as farFilter,
} from '@fortawesome/pro-regular-svg-icons';
import {} from '@fortawesome/pro-solid-svg-icons';
import {
    faUsersMedical as falUsersMedical,
    faBuilding as falBuilding,
    faClipboardList as falClipboardList,
    faUserFriends as falUserFriends,
    faFileChartLine as falFileChartLine,
    faPencil as falPencil,
    faTrash as falTrash,
    faFilePdf as falFilePdf,
    faFileExcel as falFileExcel,
    faUpload as falUpload,
    faDownload as falDownload,
    faUserCrown as falUserCrown,
    faKey as falKey,
    faHome as falHome,
    faFileExport as falFileExport,
    faFileDownload as falFileDownload,
    faBars as falBars,
    faUsersClass as falUsersClass,
    faSlidersH
} from '@fortawesome/pro-light-svg-icons';
import {} from '@fortawesome/free-brands-svg-icons';
import {FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText} from '@fortawesome/vue-fontawesome';

library.add(farAngleDown, falUserFriends, falBuilding, falFileChartLine, falClipboardList, farEye, farEyeSlash,
    farSearch, falPencil, falTrash, farTimes, falFilePdf, falFileExcel, falUsersMedical, farCheck, falUpload, falDownload, falUserCrown, falKey, falHome, falFileExport,
    falFileDownload, falBars, farSave, farFilter, falUsersClass, faSlidersH);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('font-awesome-layers-text', FontAwesomeLayersText);

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
