<template>
    <div class="landing-page-container">
        <div class="content">
            <section>
                <img class="logo" src="../assets/logo.png" alt="Rentz Direct"/>

                <h1>{{$t('landing.website_coming_soon')}}</h1>

                <a href="https://www.facebook.com/rentzdirect/" target="_blank"><p>{{$t('landing.follow_us_on')}}</p> <img src="../assets/facebook-logo.svg"/></a>
            </section>

            <section>
                <div class="form-container">
                    <h2>{{$t('landing.contact_us')}}</h2>

                    <Form @submit="submit">
                        <FormGroupTwo>
                            <FormInputText v-model="$v.details.full_name.$model" identifier="name" :label="$t('landing.full_name')"
                                           :placeholder="$t('landing.full_name')"
                                           :has-error="$v.details.full_name.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.details.full_name.required">
                                        {{ $t('validation.x_is_required', {x: $t('landing.full_name')}) }}
                                    </p>
                                </template>
                            </FormInputText>

                            <FormInputText v-model="$v.details.phone_number.$model" identifier="phone-number" :label="$t('landing.phone_num')"
                                           :placeholder="$t('landing.phone_num')"
                                           :has-error="$v.details.phone_number.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.details.phone_number.required">
                                        {{ $t('validation.x_is_required', {x: $t('landing.phone_num')}) }}
                                    </p>
                                </template>
                            </FormInputText>
                        </FormGroupTwo>

                        <FormInputText v-model="$v.details.email.$model" identifier="email-address" :label="$t('landing.email_address')"
                                       :placeholder="$t('landing.email_address')"
                                       :has-error="$v.details.email.$error">
                            <template v-slot:errors>
                                <p v-if="!$v.details.email.required">
                                    {{ $t('validation.x_is_required', {x: $t('landing.email_address')}) }}
                                </p>
                                <p v-else-if="$v.details.email.required && !$v.details.email.email">
                                    {{ $t('validation.email_address_must_be_valid') }}
                                </p>
                            </template>
                        </FormInputText>

                        <FormInputText v-model="$v.details.message.$model" identifier="message" :label="$t('landing.message')"
                                       :placeholder="$t('landing.message')"
                                       :has-error="$v.details.message.$error" :useTextarea="true">
                            <template v-slot:errors>
                                <p v-if="!$v.details.message.required">
                                    {{ $t('validation.x_is_required', {x: $t('landing.message')}) }}
                                </p>
                            </template>
                        </FormInputText>

                        <Button type="submit" className="button --primary --wide" :class="{spinner: is_submitting}">{{$t('submit')}}</Button>
                    </Form>
                </div>
            </section>
        </div>

        <Footer class="footer"/>
    </div>
</template>

<script>
    import {required, email} from 'vuelidate/lib/validators'
    import Button from "../components/Button";
    import Form from "../components/form/Form";
    import Footer from "@/components/Footer";
    import FormInputText from "@/components/form/FormInputText";
    import FormGroupTwo from "@/components/form/FormGroupTwo";

    export default {
        name: "landing-page",
        components: {FormGroupTwo, FormInputText, Footer, Form, Button},
        data() {
            return {
                details: {
                    full_name: null,
                    phone_number: null,
                    email: null,
                    message: null
                },
                is_submitting: false,
            }
        },
        validations: {
            details: {
                full_name: { required },
                phone_number: { required },
                email: { required, email },
                message: { required }
            }
        },
        methods: {
            submit() {
                this.$v.details.$touch();
                if (this.$v.details.$anyError)
                    return;

                this.is_submitting = true;

                this.$axios.post('contact-us', this.details).then(async ({data}) => {
                    this.is_submitting = false;

                    this.details = {
                        full_name: null,
                        phone_number: null,
                        email: null,
                        message: null
                    }

                    this.$v.details.$reset();

                    this.$notify({
                        title: this.$t('success'),
                        text: this.$t('landing.message_sent'),
                        type: 'success'
                    });
                }).catch(e => {
                    this.is_submitting = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$t('landing.failed_send_message'),
                        type: 'error'
                    });
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    .landing-page-container {
        @apply bg-cover bg-center bg-no-repeat min-h-screen w-full flex flex-col justify-center items-center relative pt-16;
        background-image: url('../assets/background-img.png');

        .content {
            @apply flex flex-col max-w-7xl;

            @screen lg {
                @apply flex-row justify-between w-full my-auto px-8;
            }

            @screen 2xl {
                @apply px-0;
            }

            section {
                @apply flex flex-col mb-16 px-4 justify-center;

                @screen lg {
                    @apply px-0 mb-0;
                }

                .logo {
                    @apply h-12 mb-12;
                    object-fit: contain;

                    @screen lg {
                        @apply h-16 mr-auto;
                    }

                    @screen 2xl {
                        @apply h-18;
                    }
                }

                h1 {
                    @apply text-3xl text-white font-bold mb-12 text-center;

                    @screen lg {
                        @apply text-5xl mb-16;
                    }

                    @screen 2xl {
                        @apply text-6xl;
                    }
                }

                a {
                    @apply flex flex-row bg-white rounded-lg text-facebook text-xs font-bold mx-auto px-4 py-3;

                    @screen lg {
                        @apply ml-0;
                    }

                    @screen 2xl {
                        @apply text-base;
                    }

                    & > p {
                        @apply mt-auto;
                    }

                    & > img {
                        @apply w-24 ml-2 my-auto;
                    }
                }

                & > .form-container {
                    @apply bg-white rounded-lg p-6;

                    & > h2 {
                        @apply font-bold text-primary text-2xl text-center mb-8;
                    }

                    & > Form {
                        & > Button {
                            @apply w-auto mx-auto rounded-lg py-3 text-base mt-2;
                        }
                    }
                }
            }
        }

        .footer {
            @apply mt-auto;
        }
    }
</style>
