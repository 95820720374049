export default {
    nav: {
        properties: 'Properties',
        owners: 'Owners',
        tenants: 'Tenants',
        users: 'Users',
        roles: 'Roles',
        attributes: 'Attributes',
        reports: 'Reports',
        login: 'Login',
        logout: 'Logout',
    },
    landing: {
        website_coming_soon: 'Website Coming Soon',
        follow_us_on: 'Follow us on',
        contact_us: 'Contact Us',
        full_name: 'Full Name',
        phone_num: 'Phone Number',
        email_address: 'Email Address',
        message: 'Message',
        message_sent: 'Your message has been sent!',
        failed_send_message: 'Failed to send message!'
    },
    prompts: {
        are_you_sure: 'are you sure?',
        do_you_want_to_delete_this_x: 'do you want to delete this {x}?',
    },
    validation: {
        x_is_required: '{x} is required',
        email_address_must_be_valid: 'Email Address must be valid',
        please_enter_x: 'please enter {x}',
        please_enter_longer_x: 'please enter a longer {x}',
        please_enter_shorter_x: 'please enter a shorter {x}',
        please_enter_valid_x: 'please enter a valid {x}',
        please_choose_x: 'please choose {x}',
        please_enter_number: 'please enter a number',
        please_enter_number_between_x: 'please enter a number between {x} and {y}',
        please_enter_url: 'please enter a URL',
    },
    auth: {
        unauthorized: 'Unauthorized',
        unauthenticated: 'Unauthenticated',
        forgot_password_q: 'Forgot password?',
        email: 'Email',
        password: 'Password',
        password_is_required: 'Password is required',
        email_is_required: 'Email is required',
        enter_valid_email: 'Enter a valid email',
        failed_login: 'Login Failed',
    },
    localities: {
        select_locality: 'Select locality',
        error_retrieve: 'Failed to retrieve localities',
    },
    profile: {
        my_profile: 'My Profile',
        my_listed_properties: 'My Listed Properties',
        my_rented_properties: 'My Rented Properties',
        error_generate: 'Failed to retrieve profile',
    },
    error_upload_files: 'Failed to upload files',
    error_save_files: 'Failed to save files',
    add: 'Add',
    save: 'Save',
    submit: 'Submit',
    upload: 'Upload',
    search: 'Search',
    select: 'Select',
    page_not_found: 'Page not found',
    x_per_page: '{x} per page',
    error: 'Error',
    yes: 'Yes',
    no: 'No',
    accepted: 'Accepted',
    not_accepted: 'Not Accepted',
    warning: 'Warning',
    copy: 'Copy',
    all:'All',
}
