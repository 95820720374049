<template>
    <footer class="footer-container">
        <div class="content">
            <p>© {{ year }} Rentz Direct. All Rights Reserved.</p>

            <div class="engineered-by-arkafort">
                <p>Engineered by</p>
                <img src="../assets/arkafort-logo.svg" alt="Arkafort"/>
            </div>
        </div>
    </footer>
</template>

<script>
    export default {
        name: "Footer",
        data() {
            return {

            }
        },
        computed: {
            year() {
                let date = new Date().getFullYear();
                return date;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .footer-container {
        @apply bg-grey-dark h-auto w-full px-8 py-6;
        grid-area: footer;

        .content {
            @apply max-w-7xl w-full flex flex-col items-center justify-between mx-auto;

            @screen md {
                @apply flex-row;
            }

            & > p {
                @apply text-sm text-white font-medium mb-4;

                @screen md {
                    @apply mb-0;
                }
            }

            .engineered-by-arkafort {
                @apply flex flex-row;

                & > p {
                    @apply text-sm text-white;
                }

                & > img {
                    @apply ml-2 h-4;
                }
            }
        }

    }
</style>